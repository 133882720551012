<template>
  <div>
    <div v-show="false" id="preloader">
      <div id="status">
        <div class="spinner">
          <i class="ri-loader-line spin-icon"></i>
        </div>
      </div>
    </div>
    <div id="layout-wrapper">
      <HorizontalTopbar />

      <HorizontalNav />
      <div class="main-content">
        <div class="page-content">
          <div class="container-fluid">
            <slot />
          </div>
        </div>
        <Footer />
      </div>
    </div>
  </div>
</template>

<script>
import HorizontalTopbar from "@/components/horizontal-topbar";
import HorizontalNav from "@/components/horizontal-navbar";
import Footer from "@/components/footer";

export default {
  components: {
    HorizontalTopbar,
    HorizontalNav,
    Footer,
  },
};
</script>
